import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero';
import SEO from '../components/seo';

function About() {
  return (
    <Layout>
      <SEO title="About Vanna Howard" />

      <Hero
        size=""
        title="Meet Vanna"
        subtitle=""
        color="light"
        centered
      />
      <main className="section content">
        <div className="container is-narrow">
          <p>
            I was born in Cambodia. As a child, the Khmer Rouge murdered both of my maternal
            grandparents, my father, and my three younger siblings between 1975-1979. This all
            happened before I reached the age of 10. I resettled in the U.S. at age 11 after having
            spent more than 2 years in a Thai refugee camp. As a child refugee who spoke no English,
            I overcame homelessness, abuse and tremendous adversity. I understand firsthand the
            challenges and struggles face by many. I am the first person in my family to graduate
            from college. I’ve had a great professional career and worked with and for amazing
            people. Through these experiences, I have not only become familiar with every
            neighborhood in this region, but I have learned the importance of hard work, listening
            to people’s concerns, tackling problems head on, and working in collaboration with
            community partners to craft real-world solutions to those problems. I have never taken
            for granted all that this region has done to help me achieve my American dream. I am
            fortunate to have the support from many organizations, community activists and leaders
            and mentors. I strongly feel the need to give back to a place which has been so good to
            me, where I have lived and worked for almost 3 decades and where I raised my family.
          </p>

          <p>
            For almost 12 years, I worked in the Office of Congresswoman Niki Tsongas, first as
            Constituent Services Representative and then as Greater Lowell Regional Director, to
            connect constituents to services and to address various issues such as immigration,
            housing, small business. Prior to my work with Congresswoman Tsongas, I was employed for
            more than 10 years with the Middlesex County District Attorney's Office Superior Court
            Trial Team and the Suffolk County District Attorney's Office in the Homicide Division. I
            am currently the government liaison with Lowell Community Health Center. I currently
            serve on numerous non-profit boards and commissions including the Boys & Girls Club of
            Greater Lowell, Lowell Housing Authority, Mill City Grows, Ironstone Farm, Cambodian
            Mutual Assistance Assoc, Acre Family Childcare, Cultural Organization of Lowell (COOL),
            the MA Asian American Commission, She’s Local, to name a few. I also volunteer at Cor
            Unum Meal Center, Lowell Women’s Week Planning Committee, and The Wish Project and have
            participated with Project Bread Walk for Hunger for more than 15 years. I am proud and
            honored to be part of a small group of planning and fundraising committee with dedicated
            people to see the Khmer Refugee Monument in Lowell finally happen, from the big picture
            concept to the tiniest most difficult details. I am a founder of Just Because Brunch
            where women can come together to share best practice with each other but support each
            other. This has led to a spin-off of a Dinner Club.{' '}
          </p>
          <p>
            I am married to my husband Greg and reside in downtown Lowell with two children Suzanna
            and Max and a dog named Buster.{' '}
          </p>
        </div>
      </main>
    </Layout>
  );
}

export default About;
